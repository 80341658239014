@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	// --ion-color-primary: #f1c27b;
	// --ion-color-primary-rgb: 241,194,123;
	// --ion-color-primary-contrast: #000000;
	// --ion-color-primary-contrast-rgb: 0,0,0;
	// --ion-color-primary-shade: #d4ab6c;
	// --ion-color-primary-tint: #f2c888;

	// --ion-color-secondary: #ffd89c;
	// --ion-color-secondary-rgb: 255,216,156;
	// --ion-color-secondary-contrast: #000000;
	// --ion-color-secondary-contrast-rgb: 0,0,0;
	// --ion-color-secondary-shade: #e0be89;
	// --ion-color-secondary-tint: #ffdca6;

  	// --ion-color-tertiary: #f60;
	// --ion-color-tertiary-rgb: 255,102,0;
	// --ion-color-tertiary-contrast: #000000;
	// --ion-color-tertiary-contrast-rgb: 0,0,0;
	// --ion-color-tertiary-shade: #e05a00;
	// --ion-color-tertiary-tint: #ff751a;

	--ion-color-primary: #0054e9;
	--ion-color-primary-rgb: 0,84,233;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #004acd;
	--ion-color-primary-tint: #1a65eb;

	--ion-color-secondary: #0163aa;
	--ion-color-secondary-rgb: 1,99,170;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #015796;
	--ion-color-secondary-tint: #1a73b3;

	--ion-color-tertiary: #6030ff;
	--ion-color-tertiary-rgb: 96,48,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #542ae0;
	--ion-color-tertiary-tint: #7045ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47,47,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;
}
