@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;600&display=swap');
@import '~firebaseui/dist/firebaseui.css';

* {
  font-family: 'Noto Sans SC', sans-serif !important;
}

body * {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}


// QR image modal
.qr-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  &>* {
    background-color: white;
  }
}

.qr-code-img {
  display: flex;
  flex: 1;
  justify-content: center;
}


/* Hide scrollbar for IE, Edge and Firefox */
ion-content::part(scroll) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}


ion-modal.fit-content-modal {
  --width: fit-content;
  --min-width: 300px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding-left: 2vw;
  padding-right: 2vw;
}

ion-modal.login-modal {
  .ion-page {
    max-height: 80vh;
  }
}

ion-modal.transparent-modal {
  --height: 100vh;
}

ion-modal.transparent-modal::part(backdrop) {
  background: #000;
  opacity: 1;
}

.grecaptcha-badge {
  visibility: hidden;
}

ion-button {
  ion-spinner {
    height: 20px;
  }
}

.bold {
  font-weight: bold;
}